import { CircularProgress, Container, Typography } from '@material-ui/core';
import React from 'react';
import './styles.css';

interface LoadingState {
    createdTime: Date;
    timeDiff: number;
}

class Loading extends React.Component<{}, LoadingState> {
    loadingText = () => {
        if (this.state) {
            this.startTiming();
            if (this.state.timeDiff < 10 || !this.state.timeDiff) {
                return ("Loading")
            }
            else if (this.state.timeDiff < 20) {
                return ("Searching for your property.");
            }
            else if (this.state.timeDiff < 30) {
                return ("Finding the nearest gas main.");
            }
            else if (this.state.timeDiff < 45) {
                return ("Calculating distance to nearest gas main.");
            }
            else {
                return ("Processing is taking longer than expected. Thank you for your patience.")
            }
        }
    }

    startTiming = () => {
        setInterval(() => this.setState({
            timeDiff: (new Date().getTime() / 1000) - (this.state.createdTime.getTime() / 1000)
        }), 1000)
    }

    componentDidMount() {
        this.setState({ createdTime: new Date() })
    }

    render() {
        return (
            <Container className="LoadingContainer">
                <CircularProgress />
                <Typography className="OfferHeader LoadingText">{this.loadingText()}</Typography>
            </Container>
        )
    }
}

export default Loading;