import React from 'react'
import Script from 'react-load-script'

class HeaderScripts extends React.Component<{}, {}> {
    render() {
        return (
            <Script
                url="https://maps.googleapis.com/maps/api/js?key=AIzaSyD76N0KdyCRzJ_1IjADQC0mDwc4QRcrtLM&libraries=places"
                onCreate={this.handleScriptCreate.bind(this)}
                onError={this.handleScriptError.bind(this)}
                onLoad={this.handleScriptLoad.bind(this)}
            />
        )
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }
}

export default HeaderScripts;