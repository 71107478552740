import React from "react"
import { Typography } from "@material-ui/core"
import "../components/styles.css"

export const GDP_URL = "https://mygasservices.jemena.com.au/"
const FIND_PLUMBER = "https://www.gonaturalgas.com.au/find-a-gas-specialist";
const FIND_DISTRIBUTOR = "https://jemena.com.au/help-and-advice/find-my-distributor";

const AnyOfferText = <Typography variant="body1" gutterBottom>What’s next? Simply complete an <a href={GDP_URL}>online application</a> and we’ll send you a quote. This will be referred to as a gas connection ‘offer’.  Once you’ve accepted the offer, we’ll assign a gas retailer to arrange your gas connection. If you’d prefer to choose your own gas retailer, you can contact them directly to lodge a connection application on your behalf. Your retailer will issue the gas bills once you’re connected.</Typography>

export const NonZeroCostText = (
    <div>
        <Typography variant="body1" gutterBottom>This estimate covers extension of the gas mains (if required), laying the inlet service pipework on your property and, in most cases, installation of a gas meter in your preferred location. To run your internal pipework and install your gas appliances, you will need to engage a licenced plumber or gasfitter of your choosing. <a href={FIND_PLUMBER}>Click here</a> to locate a trusted specialist near you.</Typography>
        {AnyOfferText}
    </div>
)

export const ZeroCostText = (
    <div>
        <Typography variant="body1" gutterBottom>Great news! You already have gas running past your property which means our initial estimate is that we can connect your property at no cost. This includes laying your inlet service pipework on the property and, in most cases, installation of a gas meter in your preferred location.</Typography>
        <Typography variant="body1" gutterBottom>To run your internal pipework and install your gas appliances, you will need to engage a licenced plumber or gasfitter of your choosing. <a href={FIND_PLUMBER}>Click here</a> to locate a trusted specialist near you.</Typography>
        {AnyOfferText}
    </div>
)

export const AddressNotFoundText = (
    <div>
        <Typography variant="body1" gutterBottom>Unfortunately we have been unable to locate your property in our system. If you believe your property is within <a href={FIND_DISTRIBUTOR}>Jemena’s Gas Network area</a> please complete an <a href={GDP_URL}>online connection application</a> and we’ll have one of our customer service staff manually review your request.</Typography>
    </div>
)

export const GISDownText = (
    <div>
        <Typography variant="body1" gutterBottom>Unfortunately our gas mapping system is currently unavailable. We’ll be up and running shortly so please try again later, or you can lodge an <a href={GDP_URL}>online connection application</a> now and we’ll get back to you within 2 business days.</Typography>
    </div>
)

export const NotJemenaCustomerText = (
    <div>
        <Typography variant="body1" gutterBottom>It looks like your property is not located within Jemena’s Gas Network area. <a href={FIND_DISTRIBUTOR}>Click here to find your local gas distributor</a>.</Typography>
    </div>
)

export const AlreadyConnectedText = (
    <div>
        <Typography variant="body1" gutterBottom>It looks like your home is already connected to gas. Please contact your preferred energy retailer to set up a billing account and start using your gas appliances.</Typography>
        <Typography variant="body1" gutterBottom>A full list of energy retailers can be found <a href="https://www.aer.gov.au/consumers/useful-contacts-for-customers/energy-retailer-contact-details">here</a>.</Typography>
    </div>
)

export const ConnectionUnavailableText = (
    <div>
        <Typography variant="body1" gutterBottom>It looks like the nearest gas main to your property is over 100m away. Extending our network in built-up areas is very costly, largely due to necessary restoration work. This means that given the distance we’d have to cover, connecting gas to your property is unfortunately not viable.</Typography>
    </div>
)

export const MainTooFarText = (
    <div>
        <Typography variant="body1" gutterBottom>It looks like the nearest gas main to your property is over 50m away. We estimate we’d need to charge you upwards of $7,500 to make the gas mains extension required to connect your property viable. This is because extending our network in built-up areas is very costly, largely due to necessary restoration work.</Typography>
        <Typography variant="body1" gutterBottom>If you would still like a quote to connect, please lodge an <a href={GDP_URL}>online application</a> and we’ll send you a gas connection offer.</Typography>
    </div>
)
export const RMSRoadText = (
    <div>
        <Typography variant="body1" gutterBottom>It looks like your property is on a busy road, so we'll need to take a closer look before providing you with a quote to connect gas to your home. Please lodge a connection application <a href={GDP_URL}>online application</a> so we can formally review your request and provide you with an accurate quote.</Typography>
    </div>
)

export const FallbackText = (
    <div>
        <Typography variant="body1" gutterBottom>We’ll need to take a closer look at your gas connection enquiry as it appears there may be some non-standard factors to consider in providing you with a quote to connect. Please lodge an <a href={GDP_URL}>online application</a> so we can more accurately review your request. We’ll provide you with a gas connection offer within 2 business days.</Typography>
    </div>
)

export const TermsofOffer = (
    <div>
        <Typography gutterBottom variant="caption" >The preliminary cost estimate (and information provided on this website) is based on information supplied by you or third parties, without detailed assessment. It is based on our experience of costs for project management, design, construction and supply of materials. This estimate is intended to provide an indicative cost only. It is subject to change due to limited information available and any third party or outside constraints that may affect the price. For example, the latter may include the need for community consultation. These matters may also affect the estimated costs and supply date. While we endeavour to provide an accurate estimate, we give no undertaking as to the accuracy, completeness or up-to-date nature of any information provided. You are responsible for your own independent enquiries and due diligence</Typography>
    </div>
)

export const WelcomeText = (
    <Typography color="textPrimary" variant="body2" gutterBottom>
        To get started please enter in the address to be connected, please note this tool is only designed for single stand alone residential homes and cannot be used for commercial connections, high rise or medium density connections. For these type of connections please complete a <a href={GDP_URL}>full connection application.</a>
    </Typography>
)

