import { AppBar, Grid, Toolbar, Typography, Hidden } from '@material-ui/core';
import React from 'react';
import './styles.css';

class TopAppBar extends React.Component<{}, {}> {
    render() {
        return (
            <AppBar position="static" className="AppBar">
                <Toolbar>
                    <Grid container spacing={3}>
                        <Hidden smDown>
                            <Grid item md={2} xs={12} className="AppBarTitle">
                                <img src="Jemena-logo-sml.png" alt="Jemena Logo" />
                            </Grid>
                        </Hidden>
                        <Grid item md={9} xs={12} className="AppBarTitle">
                            <Typography variant="h5" noWrap>Gas Connection Cost Estimate</Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar >
        )
    }
}

export default TopAppBar;