import React from 'react';
import './styles.css';

interface pinProps {
    lat: any;
    lng: any;
};

export default class MapPin extends React.Component<pinProps, {}> {
    render() {
        return (
            <span>
                <div className='pin'></div>
                <div className='pulse'></div>
            </span>

        );
    }
}