import React from 'react';
import IndicativeForm from './components/IndicativeForm';
import HeaderScripts from './components/HeaderScripts';
import TopAppBar from './components/TopAppBar';
import ReactGA from 'react-ga';
import './AppStyles.css';

const App: React.FC = () => {
  if (process.env.REACT_APP_USE_ANALYTICS === 'TRUE') {
    ReactGA.initialize('UA-15125081-46');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  return (
    <div className='MasterContainer'>
      <HeaderScripts />
      <TopAppBar />
      <IndicativeForm />
    </div>
  );
}

export default App;
