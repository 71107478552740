import { MenuItem, TextField } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import MapPin from './MapPin';

const googleMapLoader = require('google-map-react/lib/loaders/google_map_loader').default;

interface mapProps {
  mountAddress: Function;
};

interface mapState {
  address: string;
  mounted: boolean;
  fetchResults: boolean;
  lat: number;
  lon: number;
  showPin: boolean;
  showStartPopper: boolean;
}

class GoogleMap extends React.Component<mapProps, mapState> {
  MapRef: any;
  constructor(props: mapProps) {
    super(props);
    this.state = {
      address: '',
      mounted: false,
      fetchResults: false,
      lat: -33.8604958,
      lon: 151.2107765,
      showPin: false,
      showStartPopper: true,
    };
  }

  handleChange = (newAddress: string) => {
    this.setState({ address: newAddress });
    if (!this.state.showStartPopper) this.setState({ showStartPopper: true });
    if (newAddress.length > 4) this.setState({ fetchResults: true });
    else this.setState({ fetchResults: false });
  }

  handleSelect = (newAddress: any) => {
    geocodeByAddress(newAddress)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ address: newAddress, lat: latLng.lat, lon: latLng.lng }))
      .catch(error => console.error('Error', error));
    geocodeByAddress(newAddress)
      .then(results => this.props.mountAddress(results[0]))
    this.setState({ showPin: true })
    this.hideKeyboard();
  };

  public componentDidMount(): void {
    // If google maps is not present yet, load it via 
    // googleMapLoader from google-map-react to avoid loading the api twice
    if (!window.google) {
      googleMapLoader({ key: 'AIzaSyD76N0KdyCRzJ_1IjADQC0mDwc4QRcrtLM' })
        .then((maps: any) => {
          // make api available globaly
          window.google = { maps };
          this.setState({ mounted: true });
        })
        .catch((error: any) => {
          console.error('AutoComplete', error);
        });
    } else {
      this.setState({ mounted: true });
    }
  }

  addressInNSW = (suggestionObj: any): boolean => {
    for (var term of suggestionObj.terms) {
      if (term.value === "NSW") return true;
    }
    return false;
  }

  hideKeyboard = () => {
    this.MapRef.focus();
  }

  render() {
    return (
      <div className="AutoCompleteParent">
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          shouldFetchSuggestions={this.state.fetchResults}
          searchOptions={{
            componentRestrictions: { country: 'au' },
            types: ['geocode'],
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <TextField className="AutocompleteInput"
                {...getInputProps({
                  placeholder: 'Search Addresses ...',
                  className: 'AutocompleteInput WhiteBackground',
                })}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true, }}
              />
              <div className="AutocompleteSuggestion">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  if (this.addressInNSW(suggestion)) {
                    return (
                      <div {...getSuggestionItemProps(suggestion)}>
                        <MenuItem>{suggestion.description}</MenuItem>
                      </div>
                    );
                  }
                  else return null;
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <div style={{ height: '60vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyD76N0KdyCRzJ_1IjADQC0mDwc4QRcrtLM' }}
            center={{ lat: this.state.lat, lng: this.state.lon }}
            draggable={false}
            defaultZoom={19}
          >
            {this.state.showPin ? <MapPin lat={this.state.lat} lng={this.state.lon} /> : <span />}
          </GoogleMapReact>
        </div>
        {<button ref={(input) => { this.MapRef = input; }} className="Hidden"></button>}
      </div >
    );
  }
}

export default GoogleMap;