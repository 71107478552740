import { Address } from "../definitions/interfaces";

export function buildAddress(addressObj: any): Address { //TODO Fix up this any
  let houseNumber: string = "";
  let houseNumberSuffix: string = "";
  let postCode: string = "";
  let stateOrTerritory: string = "";
  let streetName: string = "";
  let streetType: string = "";
  let suburbOrPlaceOrLocality: string = "";
  addressObj.forEach((item: { types: any[]; long_name: any; short_name: any; }) => { //TODO Define type
    switch (item.types[0]) {
      case 'street_number':
        houseNumber = item.long_name;
        break;
      case 'route':
        streetName = item.long_name.substring(0, item.long_name.lastIndexOf(" "));
        streetType = item.long_name.split(" ").splice(-1)[0]
        break;
      case 'locality':
        suburbOrPlaceOrLocality = item.long_name;
        break;
      case 'administrative_area_level_1':
        stateOrTerritory = item.long_name;
        break;
      case 'postal_code':
        postCode = item.long_name;
        break;
      default:
        break;
    }
  });
  return (
    {
      houseNumber: houseNumber,
      houseNumberSuffix: houseNumberSuffix,
      streetName: streetName,
      streetType: streetType,
      suburbOrPlaceOrLocality: suburbOrPlaceOrLocality,
      postCode: postCode,
      stateOrTerritory: stateOrTerritory
    }
  )
}