import { Container, Typography } from '@material-ui/core';
import React from 'react';
import { AddressNotFoundText, AlreadyConnectedText, RMSRoadText, ConnectionUnavailableText, FallbackText, GISDownText, MainTooFarText, NonZeroCostText, NotJemenaCustomerText, ZeroCostText } from '../definitions/constants';
import { IndicativeAssessment } from '../definitions/interfaces';
import './styles.css';

interface ResponseProps {
    assessment: IndicativeAssessment,
}

interface ResponseState {
}

class IndicativeResponse extends React.Component<ResponseProps, ResponseState> {
    constructor(props: ResponseProps) {
        super(props);
        this.state = {
        }
    }

    generateBodyContent = (): JSX.Element => {
        console.log(this.props.assessment)
        if ((this.props.assessment.OfferExclGST === 0 || this.props.assessment.OfferExclGST === null) && this.props.assessment.ExceptionCode === null) {
            return ZeroCostText;
        }
        else if (this.props.assessment.OfferExclGST > 0) {
            return NonZeroCostText;
        }
        else if (this.props.assessment.ExceptionCode === "AddressNotFound") {
            return AddressNotFoundText;
        }
        else if (this.props.assessment.ExceptionCode === "ConnectionUnavailable") {
            return ConnectionUnavailableText;
        }
        else if (this.props.assessment.ExceptionCode === "OutsideNetwork") {
            return NotJemenaCustomerText;
        }
        else if (this.props.assessment.ExceptionCode === "AlreadyConnected") {
            return AlreadyConnectedText;
        }
        else if (this.props.assessment.ExceptionCode === "MainTooFar") {
            return MainTooFarText;
        }
        else if (this.props.assessment.ExceptionCode === "GISDown") {
            return GISDownText;
        }
        else if (this.props.assessment.ExceptionCode === "RMSRoad") {
            return RMSRoadText;
        }
        else {
            return FallbackText;
        }
    }

    generateHeaderContent = (): JSX.Element => {
        if (this.props.assessment.OfferExclGST >= 0 && this.props.assessment.ExceptionCode === null) {
            return (
                <div>
                    <Typography className="OfferHeader" variant='h4' gutterBottom>
                        Jemena estimates the Customer Contribution required to connect to Natural Gas would be: ${this.props.assessment.OfferExclGST.toFixed(2)}
                    </Typography>
                </div>
            );
        }
        else {
            return <span />;
        }
    }

    generateFooterContent = (): JSX.Element => {
        return (
            <Typography>If you require any further assistance please do not hesitate to contact Jemena on 1300 137 078.</Typography>
        )
    }

    render() {
        return (
            <Container className="AssessmentContainer WhiteBackground">
                <span>
                    {this.generateHeaderContent()}
                    {this.generateBodyContent()}
                    {this.generateFooterContent()}
                </span>
            </Container>
        )
    }
}

export default IndicativeResponse;