import { Backdrop, Button, Card, CardActions, CardContent, Container, Fade, Modal, Paper, Typography } from '@material-ui/core';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import React from 'react';
import { TermsofOffer, WelcomeText } from '../definitions/constants';
import { Application, IndicativeAssessment } from '../definitions/interfaces';
import { possibleStates } from '../definitions/types';
import { getAPIResponse } from '../functions/makeRequests';
import { buildAddress } from '../functions/requestBuilders';
import GoogleMap from './GoogleMap';
import IndicativeResponse from './IndicativeResponse';
import Loading from './Loading';
import './styles.css';

interface formProps {

};

interface formState {
    currentState: possibleStates;
    submissionApplication: Application;
    indicativeAssessment?: IndicativeAssessment;
    rawAddress: string;
    showWelcome: boolean;
}

class IndicativeForm extends React.Component<formProps, formState> {
    appInsights: any;
    constructor(props: formProps) {
        super(props);
        this.state = {
            currentState: "Loading",
            showWelcome: true,
            submissionApplication: {
                address: {},
                applicationSubtype: "DETACHED_RESIDENTIAL_PREMISES",
                applicationType: "QUOTE_NEW_CONNECTION",
                installationType: "NEW_CONNECTION",
            },
            rawAddress: "",
        };
    }

    componentDidMount() {
        return setTimeout(() => {
            this.setState({ currentState: "ChooseAddress" });
            var reactPlugin = new ReactPlugin();
            if (process.env.REACT_APP_USE_ANALYTICS === 'TRUE') {
                this.appInsights = new ApplicationInsights({
                    config: {
                        instrumentationKey: 'cc0575b1-1591-49d1-a22b-bf9bfdc71414',
                        extensions: [reactPlugin],
                    }
                });
                this.appInsights.loadAppInsights();
            }
        }, 1000);
    }

    mountAddress = (addressObj: any) => {
        this.setState({ rawAddress: addressObj.formatted_address });
        addressObj = addressObj.address_components
        this.setState({
            submissionApplication:
            {
                address: buildAddress(addressObj),
                installationType: this.state.submissionApplication.installationType,
                applicationType: this.state.submissionApplication.applicationType,
                applicationSubtype: this.state.submissionApplication.applicationSubtype
            },
        })
    }

    submitContent = () => {
        if (!this.state.indicativeAssessment) {
            return (
                <Container>
                    <Paper>
                        <Typography variant="h5" component="h3">
                            Something went wrong. Please try again.
                    </Typography>
                    </Paper>
                </Container>
            )
        }
        else {
            return (
                <span className="FillParent">
                    <IndicativeResponse assessment={this.state.indicativeAssessment} />
                    <Container className="WhiteBackground">
                        <Typography component={'span'} color="textSecondary" gutterBottom>
                            {TermsofOffer}
                        </Typography>
                        {this.resetButton()}
                    </Container>
                </span>
            )
        }
    }

    resetButton = () => {
        return (<Button onClick={this.resetState} variant="contained" className="ConfirmButton">Try Another Address</Button>)
    }

    addressSelectContent = () => {
        return (
            <Container >
                <GoogleMap mountAddress={this.mountAddress} />
                {(this.state.rawAddress ?
                    <Button
                        onClick={this.assessApplication}
                        className="ConfirmButton"
                        color="primary"
                        variant="contained">
                        Confirm Address</Button> :
                    <span />)}
            </Container >
        )
    }

    assessApplication = async () => {
        this.setState({ currentState: "Loading" });
        try {
            let assessment = await getAPIResponse(this.state.submissionApplication) as IndicativeAssessment;
            this.setState({ indicativeAssessment: assessment, currentState: "Submit" });
            if (process.env.REACT_APP_USE_ANALYTICS === 'TRUE') {
                this.appInsights.trackEvent({
                    name: "Pricing Request", properties: {
                        RequestDetails: this.state.submissionApplication,
                        IndicativeOffer: assessment,
                    }
                });
            }
        } catch (error) {
            this.setState({
                indicativeAssessment: {
                    OfferExclGST: -1,
                    ExceptionCode: "GISDown",
                }, currentState: "Submit"
            });
        }

    }

    resetState = () => {
        this.setState({
            currentState: "ChooseAddress", submissionApplication: {
                address: {},
                applicationSubtype: "DETACHED_RESIDENTIAL_PREMISES",
                applicationType: "QUOTE_NEW_CONNECTION",
                installationType: "NEW_CONNECTION",
            }, rawAddress: "",
        })
    }

    getStepContent(stepIndex: possibleStates) {
        switch (stepIndex) {
            case "ChooseAddress":
                return this.addressSelectContent();
            case "Submit":
                return this.submitContent();
            case "Loading":
                return <Loading />;
            default:
                return 'Something went wrong!';
        }
    }

    render() {
        return (
            <div>
                <Modal
                    open={this.state.showWelcome}
                    onClose={() => { this.setState({ showWelcome: false }) }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Fade in={this.state.showWelcome}>
                        <Card className="WelcomeCard">
                            <CardContent>
                                <Typography color="textPrimary" variant="body1" gutterBottom>
                                    Welcome to the Gas Connection Pricing Tool
                                </Typography>
                                {WelcomeText}
                                <Typography component={'span'} color="textSecondary" variant="caption" gutterBottom>
                                    {TermsofOffer}
                                </Typography>
                            </CardContent>
                            <CardActions className="ConfirmButton">
                                <Button size="small" variant="outlined" color="primary" onClick={() => { this.setState({ showWelcome: false }) }}>Get Started</Button>
                            </CardActions>
                        </Card>
                    </Fade>
                </Modal>
                <Container className="PrimaryContainer">
                    {this.getStepContent(this.state.currentState)}
                </Container >
            </div>
        )
    }
}

export default IndicativeForm;