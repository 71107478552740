import * as webrequest from 'request';
import { Application, IndicativeAssessment } from "../definitions/interfaces";

export async function getAPIResponse(indicativeApplication: Application) {
    let NEWCONNECTION_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '';
    let API_KEY = process.env.REACT_APP_API_KEY || '';
    let GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
    var options: webrequest.CoreOptions = {
        method: 'POST',
        headers: {
            'Ocp-Apim-Subscription-Key': API_KEY,
            'Content-Type': 'application/json',
            "Accept": "*/*",
        },
        body: JSON.stringify(indicativeApplication),
        timeout: 60000, // 60 second timeout
    };
    return new Promise((resolve, reject) => {
        webrequest.post(GATEWAY_URL + NEWCONNECTION_ENDPOINT, options, function (error: any, response: any, data: any) {
            if (error) {
                reject(error)
            }
            else if (response.statusCode >= 400) {
                reject(data)
            }
            else {
                try {
                    resolve(processAssessment(data));
                } catch (error) {
                    reject(error)
                }
            }
        }
        );
    })
}

export function processAssessment(indicativeAssessment: any): IndicativeAssessment {
    let parsedAssessment = JSON.parse(indicativeAssessment) as IndicativeAssessment;
    if (!parsedAssessment.OfferExclGST) parsedAssessment.OfferExclGST = 0;
    return parsedAssessment;
}